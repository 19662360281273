import React from 'react';
import classnames from 'classnames';
import { clients } from '../../data/clients';
import styles from './aside.module.scss';

export const Clients = props => {
  const { classes } = props;

  return (
    <div className={classnames([...(classes || [])])}>
      {clients.map((client, index) => {
        const { name, logo } = client;

        return (
          <figure key={index}>
            <img src={logo} srcSet={`${logo} 2x`} alt={name} title={name} />
          </figure>
        );
      })}
    </div>
  );
};
