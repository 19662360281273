const logoPath = '/images/logos';

export const clients = [
  {
    logo: logoPath + '/natwest-white.png',
    name: 'NatWest / RBS',
    ref: 'job-natwest'
  },
  {
    logo: logoPath + '/deutsche-bank-white.png',
    name: 'Deutsche Bank',
    ref: 'job-db'
  },
  {
    logo: logoPath + '/ft-200.png',
    name: 'Financial Times',
    ref: 'job-ft'
  },
  {
    logo: logoPath + '/telegraph-square.jpg',
    name: 'Telegraph Media Group',
    ref: 'job-telegraph'
  },
  {
    logo: logoPath + '/microsoft-white.png',
    name: 'Microsoft',
    ref: 'job-microsoft'
  },
  {
    logo: logoPath + '/deutsche-telekom-white.png',
    name: 'Deutsche Telekom UK',
    ref: 'job-dt'
  },
  {
    logo: logoPath + '/asos-white.png',
    name: 'ASOS',
    ref: 'job-asos'
  },
  {
    logo: logoPath + '/gfk-200.png',
    name: 'GFK',
    ref: 'job-gfk'
  },
  {
    logo: logoPath + '/aimia-white.png',
    name: 'Aimia Inc',
    ref: 'job-aimia'
  },
  {
    logo: logoPath + '/thefa-white.png',
    name: 'The Football Association',
    ref: 'job-thefa'
  },
  {
    logo: logoPath + '/tesco-white.png',
    name: 'Tesco Technologies',
    ref: 'job-tesco'
  }
];
